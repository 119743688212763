/*----------------------------------------------------ELEMENTS--------------------------------------------------------*/

a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*------------------------------------------------------FONTS---------------------------------------------------------*/

.ffRaleway {
  font-family: 'Raleway', sans-serif;
  font-weight: 100;
}

.ffMontserrat {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 3px;
  font-size: 13px;
}

.ltt-spacing-5 {
  letter-spacing: 8px;
}

.react-date-picker__wrapper{
  width: 100%;
  padding: 10px 10px 10px 10px;
  border: none !important;
}

.react-time-picker__wrapper{
  width: 100%;
  padding: 14px 10px 12px 10px;
  border: none !important;
}

.react-time-picker__inputGroup{
  justify-content: center;
}

.react-calendar__tile--active{
  background: #8d764a !important;
  color: white !important;
}







